const imgButton = document.querySelectorAll(".image-button");

if (imgButton) {
	imgButton.forEach((button) => {
		if (window.innerWidth < 900) {
			button.onclick = function () {
				button.classList.toggle("show-photographer");
			};
		} else {
			button.onmouseenter = function () {
				button.classList.add("show-photographer");
			};

			button.onmouseleave = function () {
				button.classList.remove("show-photographer");
			};
		}
	});
}
