import "cally";
const calendarIcon = document.querySelector('.book_calendar');
const calendar = document.querySelector('calendar-range');
const selectedDates = document.querySelector('.selected-dates');
const cancelSelection = document.querySelector('.cancel-date-selection');
const productlist = document.querySelectorAll('.tease-products.alternative');
const availableProductsTitle = document.querySelector('.product-list .avaliable-products');
const noAvailableProductsTitle = document.querySelector('.product-list .no-avaliable-products');

if(calendarIcon) {
    calendarIcon.addEventListener('click', function() {
        if(calendar.classList.contains('hidden')) {
            calendar.classList.remove('hidden');
            //scroll calendar into view
            calendar.scrollIntoView({behavior: "smooth", block: "center"});
        } else {
            calendar.classList.add('hidden');
        }
    });
}

if(calendar) {
    calendar.addEventListener('change', function(event) {
        let dates = event.target.value;
        let firstDate = dates.split('/')[0];
        let secondDate = dates.split('/')[1];
        let listOfIds = [];
        let numberOfProducts = 0;

        // For each product, get the data-iticket-productcode attribute and push it to the listOfIds array
        productlist.forEach((product, index) => {
            listOfIds.push(product.getAttribute('data-iticket-productcode'));
        });

        jQuery.ajax({
            type: "POST",
            url: rodne_scripts.ajax_url,
            data: {
                action: 'get_product_dates',
                start_date: firstDate,
                end_date: secondDate,
                product_list: listOfIds
                
            },
            success: function (output) {
                let respons = output['prices'];
                productlist.forEach((product, index) => {
                    respons.forEach((item, index) => {
                        if(product.getAttribute('data-iticket-productcode') === item['productCode']) {
                            let avaliableDates = item['dates'].filter(date => date['soldOut'] == false);
                            if(avaliableDates.length > 0) {
                                product.classList.remove('hidden');
                                numberOfProducts++;
                            } else {
                                product.classList.add('hidden');
                            }
                        }
                    });
                });
    
                const lang = document.documentElement.lang;
                let formattedFirstDate;
                let formattedSecondDate;
                
                if(lang == 'nb-NO') {
                // Format the dates
                    formattedFirstDate = new Date(firstDate).toLocaleDateString('no-NB', { day: 'numeric', month: 'long', year: 'numeric'});
                    formattedSecondDate = new Date(secondDate).toLocaleDateString('no-NB', { day: 'numeric', month: 'long', year: 'numeric' });
                } else {    
                    formattedFirstDate = new Date(firstDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric'});
                    formattedSecondDate = new Date(secondDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric'});
                }
                selectedDates.innerHTML = formattedFirstDate + '  - ' + formattedSecondDate;
        
                // Hide the calendar after selecting the dates
                calendar.classList.add('hidden');
        
                // Show the cancel selection button
                if(cancelSelection) {
                    cancelSelection.classList.remove('hidden');
                }

                if(numberOfProducts == 0) {
                    if(noAvailableProductsTitle) {
                        noAvailableProductsTitle.classList.remove('hidden');
                    }

                    if(availableProductsTitle) {
                        availableProductsTitle.classList.add('hidden');
                    }

                } else {
                    if(availableProductsTitle) {
                        const dateField = availableProductsTitle.querySelector('.date-field');
                        availableProductsTitle.classList.remove('hidden');
                        dateField.innerHTML = formattedFirstDate + '  - ' + formattedSecondDate;
                    }

                    if(noAvailableProductsTitle) {
                        noAvailableProductsTitle.classList.add('hidden');
                    }
                }
            }
        });

    });
}

if(cancelSelection) {
    cancelSelection.addEventListener('click', function() {
        selectedDates.innerHTML = selectedDates.getAttribute('data-placeholder');
        cancelSelection.classList.add('hidden');
        
        productlist.forEach((product, index) => {
            product.classList.remove('hidden');
        });

        if(availableProductsTitle) {
            availableProductsTitle.classList.add('hidden');
        }
    });
}