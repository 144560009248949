import { intersectionObserve } from "./_intersection-observer";

function loadTarget(target) {
    if (target.classList && target.classList.contains('img-loaded')) {
        return;
    }

    if (target.tagName === 'IMG') {
        target.onload = function() {
            showElement(target);
        }
        target.src = target.getAttribute('data-src');
        target.classList.add('img-loaded');
    }
}

function showElement(element) {
    if (element.classList) {
        if (!element.classList.contains('show')) {
            element.classList.add('show');
        }
    } else {
        element.className += ' show';
    }
}

const images = document.querySelectorAll('img.lazy');
observeImages(images);

export function observeImages(images) {
    intersectionObserve(images, 0, 0, (image, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !el.classList.contains('show')) {
                loadTarget(el);
            }
        })(image);
    });
}