import {intersectionObserve} from "./_intersection-observer";

let routeCoordinatesArray = [];
let pois = {};
let poisList = {};

function initMap() {
    const mapContainer = document.querySelector('#map-canvas');
    if (!mapContainer)
        return;

    intersectionObserve(mapContainer, 0, 0, (image, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !el.classList.contains('loaded')) {
                el.classList.add('loaded');
                _initMap(el);
            }
        })(image);
    });
}

function _initMap(mapContainer) {
    if(mapContainer) {  
        let routeCoordinates = mapContainer.getAttribute('data-route');
        let poisData = document.querySelectorAll('.pois span');
        routeCoordinates = routeCoordinates.split('\n');
    
        routeCoordinates.forEach(coordinate => {
            const coordinateData = coordinate.split(',');
            const lat = coordinateData[1];
            const lng = coordinateData[0];
            const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
            if (coordinateData.length > 2) {
                const id = coordinateData[2].trim()
                pois[id] = latLng;
            }
            routeCoordinatesArray.push(latLng);
        });
    
        poisData.forEach(poi => {
            const item = { title: poi.getAttribute('data-title'), description: poi.getAttribute('data-description'), icon: poi.getAttribute('data-icon') };
            poisList[poi.getAttribute('data-id')] = item;
        });

        const map = new google.maps.Map(document.getElementById("map-canvas"), {
            zoom: 12,
            center: routeCoordinatesArray[0],
            mapTypeId: "terrain",
        });
        
        const path = new google.maps.Polyline({
            path: routeCoordinatesArray,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
        
        var bounds = new google.maps.LatLngBounds();
        routeCoordinatesArray.forEach(coordinate => {
            bounds.extend(coordinate);
        });
        
        Object.keys(pois).forEach(id => {
            // add marker to map, with custom svg icon and tooltip
            if(poisList[id]) {
                const marker = new google.maps.Marker({
                    position: pois[id],
                    map: map,
                    title: poisList[id].title,
                    icon: {
                        url: '/wp-content/themes/grensesnitt/static/images/icons/' + poisList[id].icon + '-bg.svg',
                        scaledSize: new google.maps.Size(45, 45),
                    }
                });

                // add info window to map
                const infowindow = new google.maps.InfoWindow({
                    content: '<h3>' + poisList[id].title + '</h3><p>' + poisList[id].description + '</p>'
                });

                // add event listener to marker
                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                    });
                });
            }
        });
        
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
            var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
        }
        

        map.fitBounds(bounds);
        path.setMap(map);
    }
}


window.initMap = initMap;