import { gsap } from 'gsap';
import { intersectionObserve } from './_intersection-observer';

let canvas = document.getElementById('canvas');

if (canvas) {
  let context = canvas.getContext('2d');
  let resolution = window.devicePixelRatio || 1;

  let waves = [];
  let resized = false;

  let vw, vh;

  resizeCanvas();

  let waveNumber = (canvas.offsetWidth / 120) * 5;
  // let waveNumber = 300;

  let wave1 = createWave(context, {
    amplitude: 50,
    duration: 1,
    frequency: waveNumber,
    fillStyle: '#D3E6F8',
    width: vw,
    curviness: 0.1,
    height: vh,
    segments: vw / 1.5,
    waveHeight: 80,
  });

  waves.push(wave1);

  gsap.to(waves, {
    duration: 2,
    waveHeight: 60,
    curviness: 0.2,
    frequency: waveNumber,
    ease: 'sine.inOut',
    repeat: -1,
    repeatDelay: 1,
    yoyo: true,
  });

  let width = canvas.offsetWidth;
  reduce(width);

  window.addEventListener('resize', () => {
    resized = true;
    let width = canvas.offsetWidth;
    waveNumber = (canvas.offsetWidth / 120) * 5;
    reduce(width);
  });

  gsap.ticker.add(update);

  function update() {
    let len = waves.length;

    if (resized) {
      resizeCanvas();

      for (let i = 0; i < len; i++) {
        waves[i].resize(vw, vh, waveNumber);
      }

      resized = false;
    }

    context.clearRect(0, 0, vw, vh);
    context.globalCompositeOperation = 'soft-light';

    for (let i = 0; i < len; i++) {
      waves[i].draw();
    }
  }

  function createWave(context, options) {
    options = options || {};

    // API
    let wave = {
      // Properties
      amplitude: options.amplitude || 100,
      context: context,
      curviness: options.curviness,
      duration: options.duration || 2,
      fillStyle: options.fillStyle || '#D3E6F8',
      frequency: waveNumber,
      height: options.height || 600,
      points: [],
      segments: options.segments,
      tweens: [],
      waveHeight: options.waveHeight || 100,
      width: vw,
      x: options.x || 0,
      y: options.y || 0,

      // Methods
      init: init,
      resize: resize,
      draw: draw,
      kill: kill,
    };

    init();

    function kill() {
      let tweens = wave.tweens;
      let len = tweens.length;

      for (let i = 0; i < len; i++) {
        tweens[i].kill();
      }

      tweens.length = 0;
      wave.points.length = 0;
    }

    function init() {
      kill();

      let segments = wave.segments;
      let interval = wave.width / segments;

      for (let i = 0; i <= segments; i++) {
        let norm = i / segments;
        let point = {
          x: wave.x + i * interval,
          y: 1,
        };

        let tween = gsap
          .to(point, {
            duration: wave.duration,
            y: -1,
            repeat: -1,
            yoyo: true,
            ease: 'sine.inOut',
          })
          .progress(norm * wave.frequency);

        wave.tweens.push(tween);
        wave.points.push(point);
      }
    }

    function draw() {
      let points = wave.points;
      let len = points.length;

      let startY = wave.waveHeight;
      let height = wave.amplitude / 2;

      context.beginPath();
      context.moveTo(points[0].x, startY + points[0].y * height);

      for (let i = 1; i < len; i++) {
        let point = points[i];
        context.lineTo(point.x, startY + point.y * height);
      }
      context.fillStyle = wave.fillStyle;
      // context.frequency = waveNumber;
      context.lineTo(wave.x + wave.width, wave.y + wave.height);
      context.lineTo(wave.x, wave.y + wave.height);
      context.closePath();

      context.fill();

      // console.log(waveNumber);
    }

    function resize(width, height, frequency) {
      wave.width = width;
      wave.height = height;
      wave.frequency = frequency;

      let len = wave.points.length;
      let interval = wave.width / wave.segments;

      for (let i = 0; i < len; i++) {
        let point = wave.points[i];

        point.x = wave.x + i * interval;
      }
    }

    return wave;
  }

  function resizeCanvas() {
    vw = window.innerWidth;
    vh = window.innerHeight;

    canvas.width = vw * resolution;
    canvas.height = vh * resolution;

    canvas.style.width = vw + 'px';
    // canvas.style.height = vh + 'px';

    context.scale(resolution, resolution);
  }

  //For canvas / wave bottom position

  function reduce(x) {
    const bottom = document.querySelector('.footer-bottom');
    let newSpace = ((5 / Math.log(x)) * x) / 3;

    let test = bottom.offsetHeight - 77 + 'px';

    //canvas.style.bottom = bottom.offsetHeight - 75 + 'px';
  }

  //For boat animation

  const boat = document.querySelectorAll('.footer-top svg');

  intersectionObserve(boat, 0, 0, (element, intersectionRatio) => {
    (function (el) {
      if (intersectionRatio > 0 && !el.classList.contains('show')) {
        el.classList.add('show');
      }
    })(element);
  });
}
