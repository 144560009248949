const accordionElements = document.querySelectorAll(
  '.accordion .element-wrapper'
);
if (accordionElements) {
  accordionElements.forEach((element) => {
    const button = element.querySelector('button');

    button.onclick = function () {
      accordionElements.forEach((otherEl) => {
        // // if window size is bigger than 900px, close all other accordions    
        // if (window.innerWidth > 900 && otherEl != element) {
        //   otherEl.classList.remove('show-dropdown');
        // }
      });
      element.classList.toggle('show-dropdown');
    };
  });
}
