const postArchive = document.querySelector('.post-archive');

if(postArchive) {
    const filterButtons = postArchive.querySelectorAll('.cat-wrapper button');
    const filterItems = postArchive.querySelectorAll('article.tease');
    const categoryWrapper = document.querySelector('.cat-wrapper');


    filterButtons.forEach((button) => {
        button.addEventListener('click', function() {
            filterButtons.forEach((button) => {
                button.classList.remove('active');
            });
            filterItems.forEach((item) => {
                item.style.display = 'none';
            });
            const filter = this.getAttribute('data-category');
            filterItems.forEach((item) => {
                let itemCategory = item.getAttribute('data-category').split('-');
                if (itemCategory.includes(filter)) {
                    item.style.display = 'block';
                    button.classList.add('active');
                } else if (filter === 'all') {
                    item.style.display = 'block';
                    button.classList.add('active');
                }
            });

            categoryWrapper.classList.add('filtered');
        });
    });
}