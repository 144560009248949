import { tns } from "tiny-slider"
import {observeImages} from "./_lazy-image";
import {intersectionObserve} from "./_intersection-observer";

function initImageCarousel(id) {
    const element = document.querySelector('.carousel-container-' + id);
    if (!element) {
        return;
    }

    intersectionObserve(element, 0, 0, (element, intersectionRatio) => {
        if (element.classList.contains('loaded') || intersectionRatio === 0) {
            return;
        }

        element.classList.add('loaded');

        tns({
            container: '.carousel-container-' + id,
            mode: 'carousel',
            autoWidth: true,
            items: 1,
            gutter: 20,
            loop: true,
            autoplay: false,
            controlsContainer: '.image-controls-' + id,
            prevButton: '.image-previous-' + id,
            nextButton: '.image-next-' + id,
            navPosition: 'bottom',
            lazyload: false,
        });

        const observeCarouselImages = () => {
            const images = document.querySelectorAll(`.carousel-container-${id} img.lazy`);
            observeImages(images);
        };

        observeCarouselImages()
        setTimeout(observeCarouselImages, 200);
    });
}

window.initImageCarousel = initImageCarousel;