import { intersectionObserve } from "./_intersection-observer";
let popupOpen = false;
window.addEventListener('DOMContentLoaded', (event) => {
    let header = document.querySelector('header');
    let popups = document.querySelectorAll('.fixed-popup-widget');
    let openPopups = document.querySelectorAll('.open-popup');
    let openGeneralPopup = document.querySelector('.open-general-popup');
    let generalPopup = document.querySelector('.fixed-popup-widget.general');
    if (popups.length > 0) {
        popups.forEach(popup => {
            // Get exit button from popup
            let exitButton = popup.querySelectorAll('.exit, .exit-background');
            exitButton.forEach(button => {
                button.addEventListener('click', () => {
                    closePopup(popup);
                });
            });
        });
    }

    window.addEventListener('scroll', () => {
        if(popupOpen == true) {
            let openPopup = document.querySelector('.fixed-popup-widget.visible.show');
            // set scroll position to 0px not smooth
            let windowScroll = window.scrollY;
            let popuptop = openPopup.offsetTop;

            if(popupOpen && windowScroll < popuptop+120) {
                window.scrollTo(0, popuptop+120);
            }

            let bottompopup = popuptop + openPopup.offsetHeight;
            let windowBottom = windowScroll + window.innerHeight;
            if(popupOpen && windowBottom > bottompopup) {
                window.scrollTo(0, bottompopup - window.innerHeight);
            }

        }
    });

    if(openPopups.length > 0) {
        openPopups.forEach(button => {
            button.addEventListener('click', () => {
                let buttonWidget = button.dataset.widget;
                let openPopups = document.querySelectorAll('.fixed-popup-widget.visible');
                const header = document.querySelector('header');

                openPopups.forEach(popup => {
                    closePopup(popup);
                });
        
                popups.forEach(popup => {
                    if(popup.dataset.widget === buttonWidget) {
                        openPopup(popup);
                    }
                    
                    // set top to scroll position
                    popup.style.top = (window.scrollY - header.scrollHeight) +'px';
                    popup.style.height = window.innerHeight + 1000+'px';
                });
            });
        });
        
    }

    function closePopup(popup) {
        popupOpen = false;
        const body = document.querySelector('body');
        const header = document.querySelector('header');
        const wigdetScript = document.getElementById('accommodation-booking-widget');
        const html = document.querySelector('html');

        const existingWidgetScript = document.getElementById('accommodation-booking-widget');
        if(existingWidgetScript) {
            existingWidgetScript.remove();
        }

        let cbcboxOverlay = document.querySelector('#cb_cboxOverlay');
        let cbcolorbox = document.querySelector('#cb_colorbox');
        let cbuidatepickerdiv = document.querySelector('#cb-ui-datepicker-div');

        html.classList.remove('cb-fixedsticky-withoutfixedfixed');

        popup.classList.remove('show');
        body.style.overflow = 'visible';
        header.classList.add('show');
        
        setTimeout(() => {
            popup.classList.remove('visible');
        }, 600);
        
        if(wigdetScript) {
            wigdetScript.remove();
            cbcboxOverlay.remove();
            cbcolorbox.remove();
            cbuidatepickerdiv.remove();
        }

        if(popup.classList.contains('general')) {
            let selectDestination = document.querySelectorAll('select[name="destination"]');
            let selectProducts = document.querySelectorAll('select[name="product"]');
            selectDestination.forEach(select => {
                select.selectedIndex = 0;
            });
            selectProducts.forEach(select => {
                select.selectedIndex = 0;
            });

            const oldWidetContainer = document.getElementById('citybreak_activity_transport_searchform_widget');

            // if old widget exits, delete all content
            if(oldWidetContainer) {
                oldWidetContainer.innerHTML = '';
                oldWidetContainer.id = '';
            }
        }
    }

    if(openGeneralPopup) {
        openGeneralPopup.addEventListener('click', () => {
            popupOpen = true;
            const body = document.querySelector('body');
            const header = document.querySelector('header');
            // body.style.overflow = 'hidden';
            generalPopup.classList.add('visible');
            setTimeout(() => {
                generalPopup.classList.add('show');
            }, 1);

            header.classList.remove('show');
            generalPopup.style.top = (window.scrollY - header.scrollHeight) +'px';
            generalPopup.style.height = window.innerHeight + 1000+'px';
        });
    }

    if(generalPopup) {
        let selectDestination = document.querySelectorAll('select[name="destination"]');
        let selectProducts = document.querySelectorAll('select[name="product"]');
        
        selectDestination.forEach(select => {
            select.addEventListener('change', () => {
                let selectedOption = select.options[select.selectedIndex].value;
                selectProducts.forEach(product => {
                    const oldWidetContainer = document.getElementById('citybreak_activity_transport_searchform_widget');
        
                    // if old widget exits, delete all content
                    if(oldWidetContainer) {
                        oldWidetContainer.innerHTML = '';
                        oldWidetContainer.id = '';
                    }

                    if(product.id == selectedOption) {
                        product.parentElement.classList.remove('hide');
                    } else {
                        product.parentElement.classList.add('hide');
                    }
                });
            });
        });

        selectProducts.forEach(select => {
            select.addEventListener('change', () => {
                const body = document.querySelector('body');
                let selectedOption = select.options[select.selectedIndex].value;
                
                const oldWidetContainer = document.getElementById('citybreak_activity_transport_searchform_widget');
                const widgetContainer = generalPopup.querySelector('.activity-transport-widget');

                // if old widget exits, delete all content
                if(oldWidetContainer) {
                    oldWidetContainer.innerHTML = '';
                    oldWidetContainer.id = '';
                }

                widgetContainer.id = 'citybreak_activity_transport_searchform_widget';

                let widget = document.createElement('script'); 
                let lang = document.documentElement.lang;
                
                if(lang == 'nb-NO') {
                    lang = 'no';
                } else {
                    lang = 'en';
                }
                widget.type = 'text/javascript'; 
                widget.id = 'accommodation-booking-widget'; 
                widget.async = true;
                widget.src = 'https://book.rodne.no/'+ lang +'/activitytransportwidget/searchform?cbisProductId=' + selectedOption + '&enablePromocode=true'; 
                body.insertBefore(widget, body.firstChild);
            });
        });
    }

    const elements = document.querySelectorAll('#content-section');
    intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !el.classList.contains('show')) {
                if(generalPopup) {
                    setTimeout(() => {
                        openGeneralPopup.classList.add('visible');
                    }, 1);
                    openGeneralPopup.classList.add('show');
                }
            } 
        })(element);
    });

    const productPageHero = document.querySelectorAll('.single-products .product-details');
    const fixedOpenPopupProductPage = document.querySelector('.product-page-fixed-button');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (!entry.isIntersecting && !entry.target.classList.contains('show')) {
                if(fixedOpenPopupProductPage) {
                    setTimeout(() => {
                        fixedOpenPopupProductPage.classList.add('visible');
                    }, 1);
                    fixedOpenPopupProductPage.classList.add('show');
                }
            } else {
                setTimeout(() => {
                    fixedOpenPopupProductPage.classList.remove('show');
                }, 300);
                fixedOpenPopupProductPage.classList.remove('visible');
            }
        });
    }, { threshold: 0 });

    productPageHero.forEach((element) => {
        observer.observe(element);
    });
});

export function openPopup(popup) {
    popupOpen = true;
    const body = document.querySelector('body');
    const header = document.querySelector('header');

    header.classList.remove('show');
    // body.style.overflow = 'hidden';
    popup.classList.add('visible');

    const oldWidetContainer = document.getElementById('citybreak_activity_transport_searchform_widget');
    let widgetContainer = popup.querySelector('.activity-transport-widget');

    // if old widget exits, delete all content
    if(oldWidetContainer) {
        oldWidetContainer.innerHTML = '';
        oldWidetContainer.id = '';
    }
    
    const cbis_productid = popup.dataset.cbis_productid;
    var widget = document.createElement('script'); 
    let lang = document.documentElement.lang;
                
    if(lang == 'nb-NO') {
        lang = 'no';
    } else {
        lang = 'en';
    }
    
    widgetContainer.id = 'citybreak_activity_transport_searchform_widget';
    widget.type = 'text/javascript'; 
    widget.id = 'accommodation-booking-widget'; 
    widget.async = true;
    widget.src = 'https://book.rodne.no/'+ lang +'/activitytransportwidget/searchform?cbisProductId=' + cbis_productid + '&enablePromocode=true'; 
    // add widget to top of head
    body.insertBefore(widget, body.firstChild);

    setTimeout(() => {
        popup.classList.add('show');
    }, 1);
}