import './_body-view-height';
import './_body-view-width';
import './_header-height';
import './header';
import './_search';
import './_lazy-image.js';
import './_lazy-video.js';
import './_block-image.js';
import './_accordion.js';
import './_wave-animation.js';
import './_notice-banner.js';
//import './_slideshow.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if (skipHeader != undefined) {
  skipHeader.addEventListener('focusin', function (e) {
    this.classList.add('focused');
  });
  skipHeader.addEventListener('focusout', function (e) {
    this.classList.remove('focused');
  });
}

function getPosts() {
  let data = new FormData();
  data.append('action', 'get_posts');

  $.ajax({
    url: project_scripts.ajax_url,
    type: 'POST',
    data: data,
    cache: false,
    processData: false,
    contentType: false,
    error: function (data) {
      console.error(data);
    },
    complete: function (data) {
      console.log(data.responseText);
    },
  });
}

//getPosts();
import './_image-carousel-configuraiton.js';

import './_destination-carousel-configuration.js';
import './_popup.js';
import './_calendar-component.js';
import './_block-map.js';
import './_grid-colum-count.js';
import './_single-product.js';

import './_archive-faq.js';

import './_inspiration-filter.js';