const banner = document.querySelector('.notice-banner');

if (banner) {
  const headerHeight = document.querySelector('header').offsetHeight;
  const top = banner.querySelector('.top');
  const initialInfo = banner.querySelectorAll('.initial-info');
  const secondaryInfo = banner.querySelectorAll('.secondary-info');
  const button = banner.querySelector('button');
  const contentSection = document.querySelector('#content-section');
  const sections = contentSection.querySelectorAll('section');

  contentSection.style.marginTop = '0px';
  sections[0].style.marginTop = '0px';

  if (secondaryInfo.length > 0) {
    initialInfo.forEach((element) => {
      element.classList.add('hide');
    });
    secondaryInfo.forEach((element) => {
      element.style.height = element.offsetHeight + 'px';
    });

    secondaryInfo[0].style.marginBottom = '0px';
    secondaryInfo[0].style.marginTop = '0px';
    secondaryInfo[0].style.borderTop = 'transparent';

    banner.style.zIndex = '110';
    banner.style.minHeight = window.innerHeight + 'px';
    banner.style.paddingTop = headerHeight + 'px';
    top.style.height = headerHeight + 'px';
  } else {
    // banner.style.marginTop = headerHeight + 'px';
  }

  onresize = (event) => {
    const headerHeight = document.querySelector('header').offsetHeight;
    if (secondaryInfo) {
      secondaryInfo.forEach((element) => {
        if (!element.classList.contains('hide')) {
          element.style.height = 'auto';
          element.style.height = element.offsetHeight + 'px';

          banner.style.minHeight = window.innerHeight + 'px';
          banner.style.paddingTop = headerHeight + 'px';

          top.style.height = headerHeight + 'px';
        } else {
          banner.style.marginTop = headerHeight + 'px';
        }
      });
    }
  };

  button.onclick = function () {
    initialInfo.forEach((element) => {
      element.style.display = 'flex';
      setTimeout(() => {
        element.classList.remove('hide');
      }, 100);
    });
    secondaryInfo.forEach((element) => {
      element.classList.add('hide');
      element.style.height = '0px';
      setTimeout(() => {
        element.style.display = 'none';
      }, 300);
    });

    top.classList.add('hide');
    top.style.height = '0px';

    banner.style.marginTop = headerHeight + 'px';
    banner.style.paddingTop = '0px';

    setTimeout(() => {
      banner.style.minHeight = '0px';
      banner.style.zIndex = '0';
    }, 400);
  };
}
