// Menu on scroll
const breakpoint = 900;
const header = document.querySelector('header');
let menuOpen = false;

if(header) {
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;

$(window).scroll(function (e) {
    let scrollTop = $(this).scrollTop();
    let widgetOpen = document.querySelector('.citybreak-widget.visible.show');
    

    if ($(window).width() >= breakpoint && scrollTop <= 2) {
        // header.classList.remove('show', 'animate');
    }
     
    else if (scrollTop > headerHeight) {
        if (scrollTop < lastScrollTop && !widgetOpen) { // Scrolling up
            header.classList.add('show')
        } else if(menuOpen == false) {
            header.classList.remove('show');
        } 
    }
    lastScrollTop = scrollTop;
});
}

window.addEventListener('load', (event) => {
    const button = document.querySelector(".menu-button")
    const fullMenu = document.querySelector("header .full-menu")
    const header = document.querySelector("header")
    const headerWrapper = document.querySelector("header .wrapper")
    const body = document.querySelector("body");
    const parent = document.querySelectorAll(".menu-item-has-children >a")
    const pllPartent = document.querySelectorAll(".mobile-langugage-switcher li.pll-parent-menu-item")
    const langParentTouchTarget = document.querySelectorAll(".mobile-langugage-switcher li .touch-target")

    if(button) {
        button.onclick = function toggleMenu() {
            if(menuOpen == true) { 
                body.style.overflow = 'visible';
                fullMenu.classList.remove('active');
                setTimeout(function() {
                    headerWrapper.classList.remove('active');
                    header.classList.remove('active');
                    fullMenu.classList.remove('display');
                }, 300);
                console.log('close');
                menuOpen = false;
            } else {
                body.style.overflow = 'hidden';
                headerWrapper.classList.add('active');
                header.classList.add('active');
                fullMenu.classList.add('display');
                setTimeout(function() {
                    fullMenu.classList.add('active');
                    header.classList.add('show');
                }, 1);

                menuOpen = true;
            }
        };
    }


    //dropdown
    /**
     * check if parent has child with classname
     * on click add or remove class
    */
    parent.forEach(function (ele) {
        ele.addEventListener('click', function(e) {
            console.log('parent click');
            e.preventDefault();
            if (ele.parentNode.classList.contains('show-ul')) {
                window.location = e.target.href;
            }
            else {
                ele.parentNode.classList.add("show-ul");

            }
        });
    });

    langParentTouchTarget.forEach(function(ele) {
        ele.addEventListener('click', function(e) {
            console.log('lang flag click');
            e.preventDefault();
            if (ele.parentNode.classList.contains('show-ul')) {
                ele.parentNode.classList.remove("show-ul");
            }
            else {
                ele.parentNode.classList.add("show-ul");
            }
        });
    });
});