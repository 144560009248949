/*
 * Calculate header height.

 * In CSS, use the following to get the height:
 *   height: var(--headerHeight);
 */
const setupColumncCount = () => {
  const relatedContent = document.querySelector('.related-content  ');
  if (relatedContent) {
    const numOfChidren = relatedContent.childElementCount;
    document.documentElement.style.setProperty(
      '--grid-column-count',
      numOfChidren
    );
  }
};
setupColumncCount();
// window.addEventListener('resize', setupColumncCount);
