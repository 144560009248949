const tabsDropdown = document.querySelectorAll('.accordion .dropdown-tabs');
  
if (tabsDropdown) {
    tabsDropdown.forEach((element) => {
        const tabTriggers = element.querySelectorAll('.tablinks');
        const dropdowns = element.querySelectorAll('.tab-dropdown');

        tabTriggers.forEach((tabLink, index) => {
            tabLink.onclick = function () {
                dropdowns.forEach((dropdown) => {
                    dropdown.classList.remove('show-dropdown');
                });
                dropdowns[index].classList.add('show-dropdown');
                
                tabTriggers.forEach((tab, otherIndex) => {
                    tab.classList.remove('active');
                });
                tabLink.classList.add('active');
            };
        });
    });
    const tabLinks = document.querySelectorAll('.accordion .tablinks');
    // const tabDropdowns = 
    
    // accordionElements.forEach((element) => {
    //     const button = element.querySelector('button');
    
    //     button.onclick = function () {
    //         accordionElements.forEach((otherEl) => {
    //             if (otherEl != element) {
    //                 otherEl.classList.remove('show-dropdown');
    //             }
    //         });
    //         element.classList.toggle('show-dropdown');
    //     };
    // });
}
  